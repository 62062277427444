<script setup lang="ts">
import { onMounted } from 'vue';
import StarBackground from '~/components/VisionBoard/StarBackground.vue';
const { $gsap } = useNuxtApp();

const animate = () => {
  const visualizeSelector = $gsap.utils.selector('#visualize');

  $gsap.to(visualizeSelector('.dark-moon'), {
    scrollTrigger: {
      trigger: visualizeSelector('.laptop'),
      start: 'top 70%',
    },
    motionPath: {
      path: [
        { x: -200, y: -200 },
        { x: 0, y: -150 },
        { x: 100, y: -50 },
        { x: 0, y: 0 },
      ],
    },
    scale: 1,
    opacity: 1,
    ease: 'power2.out',
    duration: 3.5,
  });

  const visualizeTimeline = $gsap.timeline({
    scrollTrigger: {
      trigger: '#visualize',
      start: 'top 75%',
    },
  });
  visualizeTimeline
    .fromTo(visualizeSelector('.title'), { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 1 }, 'heading')
    .fromTo(visualizeSelector('.subtitle'), { opacity: 0, y: -10 }, { opacity: 1, y: 0, duration: 1 }, 'heading')
    .fromTo(visualizeSelector('.laptop'), { opacity: 0, scale: 0.75 }, { opacity: 1, scale: 1, duration: 1 });
};

onMounted(() => {
  animate();
});

onUnmounted(() => {
  $gsap.killTweensOf('#visualize *');
});
</script>

<template>
  <div id="visualize">
    <StarBackground>
      <div class="container py-16 md:py-24 text-center text-white flex flex-col gap-8 px-4">
        <div class="relative z-20">
          <UIHeading size="h2" styleSize="h2" class="title opacity-0"> {{ $t('landing.visualizeTheFuture') }} </UIHeading>
          <p class="max-w-3xl mx-auto mt-8 subtitle opacity-0">
            {{ $t('landing.discoverPossibilities') }}
          </p>
        </div>
        <div class="max-w-2xl w-full mx-auto relative">
          <NuxtImg
            src="/images/circle.webp"
            class="dark-moon absolute -right-[80px] -top-[120px] md:-right-[60px] z-10 -translate-x-[580px] -translate-y-[200px] opacity-0 scale-[0.2]"
          />
          <div class="relative z-20">
            <NuxtImg src="/images/landing/cvb-laptop.webp" role="presentation" class="laptop w-full z-20 -mb-[150px] md:-mb-[220px]" />
          </div>
        </div>
      </div>
    </StarBackground>
  </div>
</template>
